<template>
  <div class="d-flex justify-content-center m-6">
    <div class="spinner-border text-cherry font-size-xs">
      <span class="sr-only">Loading...</span>
    </div>
  </div>
</template>

<script>
export default {};
</script>
