<template>
  <section>
    <div class="w-100 bg-white shadow-sm rounded-lg p-4" v-if="hasBankAccount">
      <h5 class="mb-3 font-weight-bold">
        {{ $t('sparkpay.wallet.bank-account.title') }}
      </h5>
      <div class="d-flex w-100 align-items-center mb-4">
        <hs-icon variant="light" icon="university" class="h2 font-weight-light text-purple m-0" />
        <div class="ml-3 w-100 overflow-hidden">
          <h5 class="font-weight-bold mb-2">
            <template v-if="!isLoading && bankAccount.id">
              <hs-button variant="link" class="p-0 text-purple text-truncate" @click="showModal(modalId.bankAccount)">
                {{ bankName }}
              </hs-button>
            </template>
            <template v-else>
              <hs-placeholder animated width="100px" height="14px" />
            </template>
          </h5>
          <div class="d-flex w-100 justify-content-between">
            <p class="m-0">
              <template v-if="!isLoading && bankAccount.id">
                <span class="h5">{{ bankAccountInfo }}</span>
              </template>
              <template v-else>
                <hs-placeholder animated width="100px" height="14px" />
              </template>
            </p>
            <p class="font-size-xs m-0">
              <template v-if="!isLoading && bankAccount.id">
                {{ $t(`sparkpay.wallet.bank-account.type.${bankAccount.account_type}`) }}
              </template>
              <template v-else>
                <hs-placeholder animated width="100px" height="14px" />
              </template>
            </p>
          </div>
        </div>
      </div>
      <h5 class="mb-3 font-weight-bold">
        CPF/CNPJ
      </h5>
      <div class="d-flex w-100 align-items-center mb-4">
        <hs-icon variant="light" icon="id-card" class="h2 font-weight-light text-purple m-0" />
        <div class="ml-3 w-100 overflow-hidden d-flex flex-column">
          <template v-if="!isLoading && bankAccount.id">
            <span class="h5 mb-0">{{ documentNumber }}</span>
            <span class="font-size-sm hs-text-grey-30">{{ legalName }}</span>
          </template>
          <template v-else>
            <hs-placeholder animated width="100px" height="14px" class="mb-1" />
            <hs-placeholder animated width="150px" height="14px" />
          </template>
        </div>
      </div>
      <p class="m-0 font-size-sm">
        Caso seja necessário alterar os dados bancários, entre em contato via chat com nosso atendimento.
      </p>
    </div>
    <div v-else class="d-flex flex-column w-100 bg-white shadow-sm rounded-lg p-4">
      <h5 class="font-weight-bold">
        {{ $t('sparkpay.wallet.bank-account.title') }}
      </h5>
      <p class="my-3">{{ $t('sparkpay.wallet.bank-account.empty.title') }}</p>
      <MButton
        icon="plus-circle"
        :label="$t('sparkpay.wallet.bank-account.empty.add')"
        variant="primary-outline"
        @click="showModal(modalId.bankAccount)"
      >
      </MButton>
    </div>
    <hsModal
      :id="modalId.withdraw"
      size="md"
      :hideHeader="true"
      bodyClass="p-0"
      v-if="!isLoading && gateway.recipient_balance && gateway.recipient_balance.available > 0 && bankAccount !== null"
    >
      <WithdrawModal
        :account-data="{
          available: gateway.recipient_balance.available / 100,
          bank_account: bankAccount,
          gateway_id: gateway.id,
          recipient_id: gateway.split_rules[0].recipient_id,
        }"
      />
    </hsModal>
    <hsModal
      :id="modalId.bankAccount"
      :scrollable="false"
      size="md"
      :hideHeader="true"
      bodyClass="p-0"
      v-if="!isLoading"
    >
      <BankAccountModal :bankAccount="hasBankAccount ? bankAccount : {}" />
    </hsModal>
  </section>
</template>

<script>
import { mapState } from 'vuex';
import { hsModal } from '@/components';
import BankAccountModal from './BankAccountModal';
import MButton from '@/shared/components/MButton.vue';
import { GetBankMixin } from '@/sparkpay/mixins/GetBankMixin';
export default {
  mixins: [GetBankMixin],
  data() {
    return {
      modalId: {
        withdraw: 'withdraw-solicitation',
        bankAccount: 'create-bank-account',
      },
      showBalance: true,
      isLoading: false,
      gateway: {},
      bankAccount: null,
    };
  },
  components: {
    hsModal,
    BankAccountModal,
    MButton,
  },
  methods: {
    showModal(id) {
      this.$bvModal.show(id);
    },
  },
  computed: {
    ...mapState('school', ['selectedSchool']),
  },
};
</script>

<style lang="scss">
.number:focus {
  outline: none;
}
</style>
