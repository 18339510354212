<template>
  <div>
    <div v-if="!isSaving && !gatewayCreated">
      <b-img-lazy fluid :src="require('@/assets/images/my_account/header.svg')" />
      <div class="px-5">
        <h2>{{ $t('sparkpay.wallet.bank-account-modal.form.title') }}</h2>
        <p class="mb-0" v-html="$t('sparkpay.wallet.bank-account-modal.form.subtitle')"></p>
      </div>
      <div class="p-5">
        <hs-form id="create-account" @submit="createBankAccount">
          <div class="mb-2">
            <hs-multiselect
              id="bank_code"
              searchable
              :placeholder="$t('sparkpay.wallet.bank-account-modal.form.bank')"
              track-by="value"
              label="name"
              :options="getBanks()"
              :showLabels="false"
              v-model="form.bank_code"
              :disabled="showBankInfo"
            />
            <template slot="feedback" v-if="!$v.form.bank_code.$error">
              <hs-form-invalid-feedback :state="false" v-if="!$v.form.bank_code.required">
                {{ $t('validations.required') }}
              </hs-form-invalid-feedback>
            </template>
          </div>
          <div class="mb-3">
            <label>{{ $t('sparkpay.wallet.bank-account-modal.form.type') }}</label>
            <hs-multiselect
              placeholder=""
              track-by="value"
              label="label"
              :options="account_type"
              :showLabels="false"
              :searchable="false"
              v-model="form.account_type"
              :disabled="showBankInfo"
            />
            <template slot="feedback" v-if="!$v.form.account_type.$error">
              <hs-form-invalid-feedback :state="false" v-if="!$v.form.account_type.required">
                {{ $t('validations.required') }}
              </hs-form-invalid-feedback>
            </template>
          </div>
          <div class="row mb-3">
            <div class="col-8">
              <hs-input
                type="tel"
                :placeholder="$t('sparkpay.wallet.bank-account-modal.form.agency_number')"
                v-model="form.agency"
                @blur="$v.form.agency.$touch()"
                :state="!$v.form.agency.$error ? null : false"
                :disabled="showBankInfo"
              >
                <template slot="feedback" v-if="$v.form.agency.$error">
                  <hs-form-invalid-feedback :state="false" v-if="!$v.form.agency.required">
                    {{ $t('validations.required') }}
                  </hs-form-invalid-feedback>
                </template>
              </hs-input>
            </div>
            <div class="col-4">
              <hs-input
                type="tel"
                :placeholder="
                  `${$t('sparkpay.wallet.bank-account-modal.form.digit')} (${$t(
                    'sparkpay.wallet.bank-account-modal.form.optional'
                  )})`
                "
                v-model="form.agency_vd"
                :disabled="showBankInfo"
              />
            </div>
          </div>
          <div class="row mb-3">
            <div class="col-8">
              <hs-input
                type="tel"
                :placeholder="$t('sparkpay.wallet.bank-account-modal.form.account_number')"
                v-model="form.account"
                @blur="$v.form.account.$touch()"
                :state="!$v.form.account.$error ? null : false"
                :disabled="showBankInfo"
              >
                <template slot="feedback" v-if="$v.form.account.$error">
                  <hs-form-invalid-feedback :state="false" v-if="!$v.form.account.required">
                    {{ $t('validations.required') }}
                  </hs-form-invalid-feedback>
                </template>
              </hs-input>
            </div>
            <div class="col-4">
              <hs-input
                type="tel"
                :placeholder="$t('sparkpay.wallet.bank-account-modal.form.digit')"
                v-model="form.account_vd"
                @blur="$v.form.account_vd.$touch()"
                :state="!$v.form.account_vd.$error ? null : false"
                :disabled="showBankInfo"
              >
                <template slot="feedback" v-if="$v.form.account_vd.$error">
                  <hs-form-invalid-feedback :state="false" v-if="!$v.form.account_vd.required">
                    {{ $t('validations.required') }}
                  </hs-form-invalid-feedback>
                </template>
              </hs-input>
            </div>
          </div>
          <div class="mb-3">
            <label>{{ $t('sparkpay.wallet.bank-account-modal.form.document_type') }}</label>
            <div class="row">
              <div class="col-4">
                <hs-multiselect
                  placeholder=""
                  track-by="value"
                  label="label"
                  :options="document_type"
                  :showLabels="false"
                  :searchable="false"
                  v-model="form.document_type"
                  :disabled="showBankInfo || disableDocumentNumber"
                />
                <template slot="feedback" v-if="!$v.form.document_type.$error">
                  <hs-form-invalid-feedback :state="false" v-if="!$v.form.document_type.required">
                    {{ $t('validations.required') }}
                  </hs-form-invalid-feedback>
                </template>
              </div>

              <div class="col-8" v-if="form.document_type">
                <hs-group
                  v-if="form.document_type.value === 'cnpj'"
                  label-for="cpf"
                  label-class="font-weight-bold"
                  class="mb-2"
                >
                  <the-mask
                    id="cnpj"
                    placeholder="__.___.___/____-__"
                    class="form-control"
                    :mask="'##.###.###/####-##'"
                    :masked="true"
                    v-model="form.document_number"
                    :disabled="disableDocumentNumber"
                  />
                </hs-group>

                <hs-group
                  v-if="form.document_type.value === 'cpf'"
                  label-for="cpf"
                  label-class="font-weight-bold"
                  class="mb-2"
                >
                  <the-mask
                    id="cpf"
                    placeholder="___.___.___-__"
                    class="form-control"
                    :mask="'###.###.###-##'"
                    :masked="true"
                    v-model="form.document_number"
                    :disabled="disableDocumentNumber"
                  />
                </hs-group>
              </div>
              <span
                v-if="form.document_number"
                class="col-12 font-size-xs"
                v-html="$t(`sparkpay.wallet.bank-account-modal.document_informative`)"
              ></span>
              <div class="col-12 mt-3" v-if="form.document_type">
                <hs-input
                  type="text"
                  :placeholder="$t(`sparkpay.wallet.bank-account-modal.form.name.${form.document_type.value}`)"
                  v-model="form.legal_name"
                  @blur="$v.form.legal_name.$touch()"
                  :disabled="$v.form.document_type.$invalid || showBankInfo"
                  :state="!$v.form.legal_name.$error ? null : false"
                >
                  <template slot="feedback" v-if="$v.form.legal_name.$error">
                    <hs-form-invalid-feedback :state="false" v-if="!$v.form.legal_name.required">
                      {{ $t('validations.required') }}
                    </hs-form-invalid-feedback>
                  </template>
                </hs-input>
              </div>
            </div>
          </div>
          <p class="font-size-sm">
            {{ $t('sparkpay.wallet.bank-account-modal.form.alert') }}
          </p>
          <hs-button
            block
            id="submit-button"
            :variant="$v.form.$invalid || isSaving || showBankInfo ? 'light' : 'primary'"
            type="submit"
            :disabled="$v.form.$invalid || isSaving || showBankInfo"
          >
            {{ $t('sparkpay.wallet.bank-account-modal.form.confirm') }}
          </hs-button>
        </hs-form>
      </div>
    </div>
    <LoadingModal v-if="isSaving" />
    <div v-if="gatewayCreated && !isSaving">
      <div class="text-white text-center p-5 bg-success">
        <h1 class="display-4 font-weight-light mb-2"><hs-icon icon="check-circle" /></h1>
        <h2>{{ $t('sparkpay.wallet.bank-account-modal.success.title') }}</h2>
        <p class="m-0">{{ $t('sparkpay.wallet.bank-account-modal.success.subtitle') }}</p>
      </div>
      <div class="p-5">
        <div class="d-flex mb-4">
          <hs-icon variant="light" icon="university" />
          <div class="ml-3">
            <h5 class="font-weight-bold mb-3">{{ form.bank_code.bank_code | bank_name }}</h5>
            <h6 class="mb-2" v-if="form.document_type.value === 'cpf'">
              CPF: <strong>{{ form.document_number | cpf }}</strong>
            </h6>
            <h6 class="mb-2" v-if="form.document_type.value === 'cnpj'">
              CNPJ: <strong>{{ form.document_number | cnpj }}</strong>
            </h6>
            <h6 class="mb-2">
              {{ $t('sparkpay.wallet.bank-account-modal.success.agency') }}:
              <strong>{{ form.agency }}-{{ form.agency_vd }}</strong>
            </h6>
            <h6>
              {{ $t('sparkpay.wallet.bank-account-modal.success.account') }}:
              <strong>{{ form.account | bank_account(form.account_vd, false) }}</strong>
            </h6>
          </div>
        </div>
        <hs-button
          block
          variant="success"
          type="button"
          @click="
            () => {
              $bvModal.hide('create-bank-account');
              $router.go('sparkpay/wallet');
            }
          "
        >
          {{ $t('actions.ok') }}
        </hs-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { validationMixin } from 'vuelidate';
import { required, numeric } from 'vuelidate/lib/validators';
import { gatewayService } from '@/services';
import { hsForm } from '@/components';
import { TheMask } from 'vue-the-mask';
import GeneralHelper from '@/shared/helpers/general';
import LoadingModal from './LoadingModal';
import toastHelper from '@/shared/helpers/toast';
import cnpjAndCpfIsValid from '@/shared/helpers/CustomValidations/cnpjAndCpfIsValid';
import debug from 'debug';

import Tracking from '@/shared/helpers/tracking';
import sparkpay from '@/sparkpay/services/sparkpay';

const AccountTypeByDocument = {
  cpf: 'pessoa_fisica',
  cnpf: 'pessoa_juridica',
};

const logging = debug('hs:bank-account');

export default {
  mixins: [validationMixin],
  props: {
    bankAccount: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      form: {
        bank_code: null,
        account_type: null,
        agency: null,
        agency_vd: null,
        account: null,
        account_vd: null,
        document_type: null,
        document_number: null,
        legal_name: null,
      },
      disableDocumentNumber: false,
      showBankInfo: false,
      account_type: [
        {
          label: this.$t('sparkpay.wallet.bank-account.type.conta_corrente'),
          value: 'conta_corrente',
        },
        {
          label: this.$t('sparkpay.wallet.bank-account.type.conta_poupanca'),
          value: 'conta_poupanca',
        },
        {
          label: this.$t('sparkpay.wallet.bank-account.type.conta_corrente_conjunta'),
          value: 'conta_corrente_conjunta',
        },
        {
          label: this.$t('sparkpay.wallet.bank-account.type.conta_poupanca_conjunta'),
          value: 'conta_poupanca_conjunta',
        },
      ],
      document_type: [{ label: 'CPF', value: 'cpf' }, { label: 'CNPJ', value: 'cnpj' }],
      isSaving: false,
      gatewayCreated: false,
    };
  },
  validations: {
    form: {
      bank_code: {
        required,
      },
      account_type: {
        required,
      },
      agency: {
        required,
        numeric,
      },
      account: {
        required,
        numeric,
      },
      account_vd: {
        required,
      },
      document_type: {
        required,
      },
      document_number: {
        cnpjAndCpfIsValid,
      },
      legal_name: {
        required,
      },
    },
  },
  components: { LoadingModal, hsForm, TheMask },
  computed: {
    ...mapState('school', ['selectedSchool']),
  },
  filters: {
    bank_name(value) {
      const { name, bank_code } = gatewayService.getBank(value);
      return `${bank_code} - ${name}`;
    },
    bank_account(value, dv, mask = true) {
      return `${GeneralHelper.bankAccount(value, mask)}-${dv}`;
    },
    cpf(value) {
      return GeneralHelper.cpf(value);
    },
    cnpj(value) {
      return GeneralHelper.cnpj(value);
    },
  },
  methods: {
    ...mapActions('school', ['updateSchool']),
    dispatchBankAccountCreatedEvent(bankInfo) {
      Tracking.bankAccountCreated({
        bank_code: bankInfo.bank_code,
        bank_name: this.form.bank_code.name,
        type: AccountTypeByDocument[bankInfo.document_type],
        school_id: this.selectedSchool.id,
      });
    },
    async createBankAccount() {
      if (this.showBankInfo) return;
      this.isSaving = true;

      let document = this.form.document_type.value;

      await this.updateSchool({ id: this.selectedSchool.id, [document]: this.form.document_number });

      const bankInfo = {
        ...this.form,
        document_type: this.form.document_type.value,
        account_type: this.form.account_type.value,
        bank_code: this.form.bank_code.bank_code,
        document_number: this.form.document_number.replace(/\D+/g, ''),
        agency_vd: this.form.agency_vd ? this.form.agency_vd : 0,
      };

      sparkpay.bankAccount
        .create(bankInfo)
        .then(res => {
          if (res && res.bank_account.id) {
            this.gatewayCreated = true;
            this.dispatchBankAccountCreatedEvent(bankInfo);
          }
        })
        .catch(error => {
          logging('failed create gateway account', error);
          toastHelper.dangerMessage(this.$t('sparkpay.wallet.bank-account.error'));
        })
        .finally(() => (this.isSaving = false));
    },
    getBanks() {
      return gatewayService.getBank().map(bank => ({ ...bank, name: bank.name.toUpperCase() }));
    },
    setFormWithBankAccount(bank) {
      this.form = {
        ...bank,
        bank_code: gatewayService.getBank(bank.bank_code),
        account_type: this.account_type.find(type => type.value === bank.account_type),
        document_type: this.document_type.find(type => type.value === bank.document_type),
      };
      this.showBankInfo = true;
      this.disableDocumentNumber = true;
    },
    setFormWithSchoolInfo(document_number, legal_name, document_type) {
      this.form.document_number = document_number;
      this.form.legal_name = legal_name;
      this.form.document_type = this.document_type[document_type];
      this.disableDocumentNumber = true;
    },
  },
  created() {
    const { bankAccount, selectedSchool } = this;
    if (bankAccount && bankAccount.id) {
      this.setFormWithBankAccount(bankAccount);
    } else if (selectedSchool.cnpj) {
      this.setFormWithSchoolInfo(selectedSchool.cnpj, selectedSchool.company_name, 1);
    } else if (selectedSchool.cpf) {
      this.setFormWithSchoolInfo(selectedSchool.cpf, selectedSchool.company_name, 0);
    } else {
      this.form.document_type = this.document_type[0];
    }
  },
};
</script>

<style lang="scss" scoped>
/deep/ .hs-multiselect .multiselect--disabled {
  opacity: 1;
}
</style>
